import React, { memo } from "react"

const Message = ({ name, message, top, left, randScale, float }) => {
  return (
    <>
      <div className="msg-point" style={{ top: top, left: left }}>
        <div className={`${float} z-switch`}>
          <div
            className="single-msg"
            style={{
              MozTransform: `scale(${randScale})`,
              WebkitTransform: `scale(${randScale})`,
            }}
          >
            <div className="circle"></div>
            <p className="message">{message}</p>
            <div className="divide" />
            <p className="name">{name}</p>
          </div>
        </div>
      </div>
    </>
  )
}

function areEqual(prevProps, nextProps) {
  if (prevProps.top === nextProps.top) return true
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
}

export default memo(Message, areEqual)
