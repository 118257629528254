import React from "react"

function MsgMessage() {
  return (
    <div className="alert fade-in">
      <p>Please include a message.</p>
    </div>
  )
}

export default MsgMessage
