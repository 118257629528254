import React from "react"
import rvLogo from "../images/rv_icon_white.png"
import arrow from "../images/arrow.svg"

export default function Footer() {
  return (
    <>
      <section className="footer">
        <section className="centered">
          <div className="rv-logo">
            <img
              // className={`logo`}
              src={rvLogo}
              alt="Rescue Vessel Logo"
              style={{ width: "70px" }}
            />
          </div>
          <div className="footer-content">
            <p className="footer-p lh-20">
              If you made it this far, wow! We hope you enjoyed this project.
              Maybe you even learned a little something. Thank you again for
              being a great collaborator. If you’d like to check out more of our
              work, have a look.
            </p>
            <div className="arrow">
              <div className="line" />
              <img
                className="arrow-head"
                src={arrow}
                alt="yellow arrow"
                // style={{ width: "50px" }}
              />
            </div>
            <a
              href="http://www.rescuevessel.com"
              target="_blank"
              rel="noreferrer"
            >
              <p>rescuevessel.com</p>
            </a>
          </div>
          <div className="footer-copyright">
            <p>Copyright © {new Date().getFullYear()} Rescue Vessel</p>
          </div>
        </section>
      </section>
    </>
  )
}
