import React from "react"
import Msg from "../components/Msg"

export default function MessageField({ messages }) {
  return (
    <section className="field">
      <div>
        {messages.map((msg, index) => {
          const randScale = Math.floor(Math.random() * 50 + 10) * 0.01
          const randFloat = Math.floor(Math.random() * 5) + 1
          return (
            <Msg
              key={index}
              message={msg.message}
              name={msg.name}
              top={msg.top}
              left={msg.left}
              randScale={randScale}
              float={`float${randFloat}`}
            />
          )
        })}
      </div>
    </section>
  )
}
