import React, { useState } from "react"
import NewMessage from "./NewMessage"
import MessageField from "./MessageField"

export default function Messages() {
  const [messages, updateMessages] = useState([])
  return (
    <>
      <section className="centered">
        <h2>And now - the fun part</h2>
        <div className="grid-2">
          <p className="ph">
            The impact and significance of this project cannot be understated.
            More than anything, we hope this sends you down a bit of a rabbit
            hole. As the James Webb Space Telescope gazes at galaxies that are
            over 13 billion light years away from us, what do you hope they
            find?
          </p>

          <NewMessage updateMessages={updateMessages} />
        </div>
      </section>
      <MessageField messages={messages} />
    </>
  )
}
