import React from "react"
import mobileSVG from "../images/mobileSVG.svg"

export default function MobileWebb() {
  return (
    <>
      <section className="mobile">
        {/* <h1>This is a mobile phone dummy</h1> */}
        <img
          // className={`logo`}
          src={mobileSVG}
          alt="Please use desktop browser"
          //   style={{ width: "70px" }}
        />
      </section>
    </>
  )
}
