import React, { useRef, useState, useEffect } from "react"
import { useSpring } from "react-spring"
import { a } from "@react-spring/three"

const Hex2 = props => {
  const mesh = useRef()
  const [angle] = useState(props.angle)
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(0)

  const { spring } = useSpring({
    spring: active,
    config: { mass: 2, tension: 300, friction: 50, precision: 0.001 },
  })

  const rotationX = spring.to([0, 1], [1.57 + angle, 1.57])
  const rotationY = spring.to([0, 1], [1.57 + angle, 1.57])
  const rotationZ = spring.to([0, 1], [angle * 2, 0])
  const color = spring.to([0, 1], ["#dac671", "gold"])

  function engaged() {
    setHover(true)
    if (!active) {
      setActive(1)
      props.changeMessage()
    }
  }

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto"
  })

  return (
    <a.mesh
      {...props}
      ref={mesh}
      // rotation={[1.57 + angle, 1.57 + angle, angle * 2]}
      rotation-x={rotationX}
      rotation-y={rotationY}
      rotation-z={rotationZ}
      onPointerOver={engaged}
      onPointerOut={() => setHover(false)}
      // onClick={engaged}
      castShadow
      receiveShadow
    >
      <cylinderBufferGeometry attach="geometry" args={[5, 5, 0.5, 6]} />
      <a.meshStandardMaterial attach="material" color={color} />
    </a.mesh>
  )
}

export default Hex2
