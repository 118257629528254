import React, { useRef } from "react"
import { useFrame } from "@react-three/fiber"
import * as THREE from "three"

function SpotLight({ complete }) {
  const light = useRef()
  const vec = new THREE.Vector3(25, 3, 8)
  useFrame(() => {
    if (complete) {
      setTimeout(() => {
        light.current.position.lerp(vec, 0.03)
      }, 200)
    }
  })

  return (
    <spotLight
      ref={light}
      castShadow
      intensity={0.5}
      shadow-mapSize-height={512}
      shadow-mapSize-width={512}
      penumbra={1}
      position={[30, 0, 3]}
      // lookAt={[0, 0, 0]}
    />
  )
}

export default SpotLight
