import React, { useRef } from "react"
import { useFrame } from "@react-three/fiber"
import * as THREE from "three"

function CenterCircle(props) {
  const mesh = useRef()
  const vec = new THREE.Vector3(0, 0, 0)
  //   const [color, setColor] = useState([226, 173, 44])

  const startColor = new THREE.Color(0x000000)
  const endColor = new THREE.Color("gold")

  useFrame(() => {
    if (props.complete) {
      setTimeout(() => {
        mesh.current.position.lerp(vec, 0.03)
        mesh.current.material.color.copy(startColor).lerpHSL(endColor, 0.85)
      }, 400)
    }
  })

  return (
    <mesh
      {...props}
      ref={mesh}
      rotation={[1.57, 1.57, 0]}
      position={[0, 0, -100]}
      // onPointerOver={() => setHover(true)}
      // onPointerOut={() => setHover(false)}
      castShadow
      receiveShadow
    >
      <cylinderBufferGeometry attach="geometry" args={[2.5, 2.65, 0.5, 36]} />
      <meshPhysicalMaterial attach="material" color={startColor} />
    </mesh>
  )
}

export default CenterCircle
