import React from "react"
import { Helmet } from "react-helmet"
import rvLogo from "../images/rv_icon_white.png"
import logo from "../images/OriginOfLight_Logo.svg"

export default function Header() {
  return (
    <div className="header">
      <Helmet>
        <meta charSet="utf-8" />
        <title>RV x NASA x East One</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <div className="rv-logo">
        <img
          // className={`logo`}
          src={rvLogo}
          alt="Rescue Vessel Logo"
          style={{ width: "70px" }}
        />
      </div>
      <div className="logo">
        <img src={logo} alt="Origin of Light Logo" />
      </div>
    </div>
  )
}
