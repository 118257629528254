import React from "react"

function LimitMessage() {
  return (
    <div className="alert fade-in">
      <p>'150 character limit.' - NASA</p>
    </div>
  )
}

export default LimitMessage
