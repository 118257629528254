import React, { useState, useRef, useEffect } from "react"
import NameMessage from "./NameMessage"
import MsgMessage from "./MsgMessage"
import LimitMessage from "./LimitMessage"
import Airtable from "airtable"

const base = new Airtable({ apiKey: "key4uyieh7qD5vBqt" }).base(
  "appoQSSACHVoyRWoT"
)

export default function NewMessage({ updateMessages }) {
  const nameRef = useRef(null)
  const msgRef = useRef(null)
  const [nameValid, setNameValid] = useState(false)
  const [msgValid, setMsgValid] = useState(false)
  const [limitHit, setLimitHit] = useState(false)
  const [name, setName] = useState(null)
  const [msg, setMsg] = useState(null)
  const [charCount, setCharCount] = useState(0)

  const handleSubmit = e => {
    e.preventDefault()
    if (nameRef.current.value && msgRef.current.value) {
      setName(nameRef.current.value)
      setMsg(msgRef.current.value)
      const raw = `{ 'text': '${nameRef.current.value} says: ${msgRef.current.value}'}`

      const requestOptions = {
        method: "POST",
        body: raw,
        proxy: false,
        redirect: "follow",
      }

      fetch(
        "https://hooks.slack.com/services/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",
        requestOptions
      )
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log("error", error))
    } else if (nameRef.current.value === "") {
      setNameValid(true)
    } else if (msgRef.current.value === "") {
      setMsgValid(true)
    }
  }

  useEffect(() => {
    if (charCount === 150) {
      // console.log("Limit Hit")
      setLimitHit(true)
      setTimeout(() => {
        setLimitHit(false)
      }, 2500)
    }
  }, [setLimitHit, charCount])

  const handleUserKeyPress = e => {
    if (e.key === "Enter") {
      handleSubmit(e)
    }
  }

  useEffect(() => {
    if (nameValid)
      setTimeout(() => {
        setNameValid(false)
      }, 2500)
    if (msgValid)
      setTimeout(() => {
        setMsgValid(false)
      }, 2500)
  }, [nameValid, msgValid])

  const getDist = (x1, y1, x2, y2) => {
    let dist = Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1))
    return dist
  }

  useEffect(() => {
    // Send a new message to Airtable
    if (name === null) return
    base("Webb_messages").create(
      [
        {
          fields: {
            Name: `${name}`,
            Message: `${msg}`,
          },
        },
      ],
      function (err, records) {
        if (err) {
          console.error(err)
          return
        }
        records.forEach(record => console.log(record.getId()))
      }
    )
    nameRef.current.value = null
    msgRef.current.value = null
  }, [name, msg])

  useEffect(() => {
    // Gets the Airtable entries, checks the distance to others, updates field
    const width = document.querySelector(".field").clientWidth
    const height = document.querySelector(".field").clientHeight
    updateMessages([])
    base("Webb_messages")
      .select({ view: "Grid view" })
      .eachPage((records, fetchNextPage) => {
        let checkedCircles = []
        records.forEach(record => {
          let attempts = 0
          while (true) {
            let shouldAdd = true
            let added = false
            const posX = Math.floor(Math.random() * (width - 275) + 25)
            const posY = Math.floor(Math.random() * (height - 275) + 1)

            for (let i = 0; i < checkedCircles.length; i++) {
              let other = checkedCircles[i]
              let dist = getDist(posX, posY, other.left, other.top)
              if (dist < 120) shouldAdd = false
            }
            if (shouldAdd) {
              checkedCircles.push({
                name: record.fields.Name,
                message: record.fields.Message,
                top: posY,
                left: posX,
              })
              added = true
            }
            if (added) break // break while loop
            attempts++
            if (attempts > 2000) break
          } // end while loop
        })
        updateMessages(checkedCircles)
        fetchNextPage()
      })
  }, [name, updateMessages])

  return (
    <div>
      <form className="form" onSubmit={handleSubmit}>
        <input className="inputName" placeholder="Name" ref={nameRef} />
        <textarea
          maxLength="150"
          className="inputMessage"
          placeholder="Message..."
          ref={msgRef}
          onChange={e => setCharCount(e.target.value.length)}
          onKeyPress={handleUserKeyPress}
        />
        <button className="btn" onClick={handleSubmit}>
          Submit
        </button>
      </form>
      <div className="alert-wrap">
        {nameValid ? <NameMessage /> : null}
        {msgValid ? <MsgMessage /> : null}
        {limitHit ? <LimitMessage /> : null}
      </div>
    </div>
  )
}
