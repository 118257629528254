import React from "react"
import HexGrid from "./HexGrid"
import Header from "./Header"
import Footer from "./Footer"
import Messages from "./Messages"
import GridDivider from "./GridDivider"
import GridDivider2 from "./GridDivider2"

export default function DesktopWebb() {
  return (
    <div>
      <section className="centered">
        <Header />
        <h2>Welcome, friend</h2>
        <p className="ph just">
          If you’re reading this, thank YOU. We’re only able to do what we do
          because of partnerships and collaborations with people like yourself.
          As a way of showing our gratitude, we send out a custom bag of coffee
          each year. You may even be drinking said coffee as you read this. Not
          only is it a chance to work with our pals at East One each year, it’s
          also a chance to draw attention to something truly amazing. More and
          more we are being inundated with information — some of it good, most
          of it bad. All a part of a constant bid for our attention at every
          waking (or resting) moment.
        </p>
        <p className="ph just">
          In the midst of being tossed about in these waves of information, we
          understandably miss a lot. There’s only so much one person can take
          in. Most of what you absorb today would lead you to believe we’re
          living in a late-stage capitalist, apocalyptic hellscape. And while
          that isn’t entirely untrue, incredible things are still happening! We
          use this mentality as a framework as we consider a theme. It selfishly
          gives us a chance to research some good news — amazing news really —
          and it may give you a chance to learn something new. Everyone wins.
        </p>
        <p className="ph just">
          If this is not your first year receiving coffee from us you may have
          noticed a theme. We’ve gone from the moons of Saturn, to the comet
          Neowise, and now to the James Webb Space Telescope. It’s pretty clear
          that we are fans of this universe we’re a part of. But it’s more than
          that. Taking a moment to consider space and time — particularly on
          this scale — always gives us perspective. It encourages us to dream
          about the mysteries of our universe and our place in it. It grounds us
          in a way that helps us see and appreciate all the wonders of our
          amazing planet.
        </p>
        <GridDivider />
        <h2>About the space telescope</h2>
        <div className="grid-2">
          <p className="ph just">
            On December 25, 2021 the James Webb Space Telescope{" "}
            <a
              href="https://youtu.be/HNqhoeytA0I?t=98"
              target="_blank"
              rel="noreferrer"
            >
              left Earth
            </a>{" "}
            — a massive undertaking that was 25 years in the making. Thanks to
            this incredible feat of science we will soon be receiving images and
            data{" "}
            <a
              href="https://youtu.be/rQIdB0UvysA?t=74"
              target="_blank"
              rel="noreferrer"
            >
              unlike any that have ever existed before
            </a>
            . Webb will explore the formation of our universe and its galaxies,
            the lifecycle of stars, and continue our search for{" "}
            <a
              href="https://www.youtube.com/watch?v=QlvzCGR90_Q"
              target="_blank"
              rel="noreferrer"
            >
              life on other planets.
            </a>
          </p>
          <p className="ph just">
            The iconic feature of the{" "}
            <a
              href="https://www.youtube.com/watch?v=I2GhFSInBqA"
              target="_blank"
              rel="noreferrer"
            >
              space telescope
            </a>{" "}
            is its 18 hexagonal mirrors. The mirrors needed to be{" "}
            <a
              href="https://youtu.be/I2GhFSInBqA?t=431"
              target="_blank"
              rel="noreferrer"
            >
              folded up
            </a>{" "}
            to be launched into space, and at the start of this year they were
            successfully deployed. Soon, tiny mechanical motors will adjust the
            angles of the mirrors in order to achieve a{" "}
            <a
              href="https://www.space.com/james-webb-space-telescope-first-photos-unveiled"
              target="_blank"
              rel="noreferrer"
            >
              single perfect focus
            </a>
            . Roll over the mirrors below and give it a try.
          </p>
        </div>
        <HexGrid />
        <GridDivider2 />
      </section>
      <Messages />
      <Footer />
    </div>
  )
}
