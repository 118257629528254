import { Canvas } from "@react-three/fiber"
import React, { useState, useEffect } from "react"
import Hex2 from "./Hex2"
import CenterCircle from "../components/CenterCircle"
import SpotLight from "../components/SpotLight"

export default function HexGrid() {
  const [complete, setComplete] = useState(false)
  const [count, setCount] = useState(18)

  const hexPositions = [
    [-15.5, 8.9, 0],
    [-15.5, 0, 0],
    [-15.5, -8.9, 0],
    [-7.75, 13.35, 0],
    [-7.75, 4.45, 0],
    [-7.75, -4.45, 0],
    [-7.75, -13.35, 0],
    [0, 17.8, 0],
    [0, 8.9, 0],
    [0, -8.9, 0],
    [0, -17.8, 0],
    [7.75, 13.35, 0],
    [7.75, 4.45, 0],
    [7.75, -4.45, 0],
    [7.75, -13.35, 0],
    [15.5, 8.9, 0],
    [15.5, 0, 0],
    [15.5, -8.9, 0],
  ]

  const hexLabels = [
    "A1",
    "A2",
    "A3",
    "A4",
    "A5",
    "A6",
    "B1",
    "B2",
    "B3",
    "B4",
    "B5",
    "B6",
    "C1",
    "C2",
    "C3",
    "C4",
    "C5",
    "C6",
  ]

  const changeMessage = () => {
    setCount(count - 1)
  }

  useEffect(() => {
    if (count < 1) {
      setComplete(true)
    }
  }, [complete, count])

  const rand = () => {
    let min = -1
    let max = 1
    let randNumber = (Math.random() * (max - min) + min) / 2
    return randNumber
  }

  return (
    <>
      <div className="hex">
        <div className="hex-info">
          <div className="objective lh-20">
            <h3>Objective</h3>
            <p className="lh-20">
              Achieve a singular perfect focus. Roll over the mirrors until they
              are all in alignment. Yes, you.
            </p>
          </div>
          <div className="mirror-count">
            <h3>Mirrors out of alignment</h3>
            <h1>{count === 0 ? "Legend" : `${count}`}</h1>
          </div>
        </div>
        <div className="f-ratio">
          <div className="lh-20">
            <h3>Effective focal ratio</h3>
            <div className="f-box">
              <p>f/20</p>
            </div>
          </div>
        </div>
        <div className="f-length">
          <div className="lh-20">
            <h3>Effective focal length</h3>
            <div className="f-box">
              <p>131.4m</p>
            </div>
          </div>
        </div>
        <div className="hex-table">
          <div className="f-table">
            {hexLabels.map((label, i) => {
              return (
                <div key={i}>
                  <p>{label}</p>
                </div>
              )
            })}
          </div>
        </div>
        <div className="hex-container">
          <Canvas shadowMap camera={{ position: [0, 0, 50] }}>
            <ambientLight intensity={0.05} />
            <SpotLight complete={complete} />
            <CenterCircle complete={complete} />
            {hexPositions.map((pos, index) => (
              <Hex2
                complete={complete}
                changeMessage={changeMessage}
                key={index}
                name={"A" + String(index + 1)}
                angle={rand}
                position={pos}
              />
            ))}
          </Canvas>
        </div>
      </div>
    </>
  )
}
