import React from "react"
import "../components/gridDividerStyle.scss"

export default function GridDivider() {
  return (
    <div className="grid-divider">
      <div className="grid-single" />
      <div className="grid-single" />
      <div className="grid-single" />
      <div className="grid-single" />
      <div className="grid-single" />
      <div className="grid-single" />
      <div className="grid-single" />
      <div className="grid-single" />
      <div className="grid-single" />
      <div className="grid-single" />
      <div className="grid-single" />
      <div className="grid-single">
        <div className="grid-circle1 grid-circle" />
        <div className="grid-circle2 grid-circle" />
      </div>
      <div className="grid-single" />
      <div className="grid-single" />
      <div className="grid-single" />
      <div className="grid-single" />
      <div className="grid-single" />
      <div className="grid-single" />
      <div className="grid-single" />
      <div className="grid-single" />
    </div>
  )
}
